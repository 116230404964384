import React from 'react'
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { DarkButton, SecondaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { useRedirect } from '@obeta/data/lib/hooks/useRedirect'

const Custom404: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { redirectToStartPage } = useRedirect()

  return (
    <>
      <Typography variant={'h1'} align={'center'} marginTop={'5rem'}>
        {t('NOTFOUND.OOPS')}
      </Typography>
      <Typography variant={'h2'} align={'center'} marginTop={'0.625rem'}>
        {t('NOTFOUND.ERROR_OCCURED')}
      </Typography>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography
          color={'text.secondary'}
          fontSize={'1.125rem'}
          fontWeight={900}
          lineHeight={'1.575rem'}
          align={'center'}
          marginTop={'1.25rem'}
        >
          {t('NOTFOUND.404_NOT_FOUND')}
        </Typography>
        <Typography
          color={'text.secondary'}
          fontSize={'1.125rem'}
          fontWeight={400}
          lineHeight={'1.575rem'}
          align={'center'}
        >
          {t('NOTFOUND.PAGE_MOVED_OR_DELETED')}
        </Typography>
        <Typography
          color={'text.secondary'}
          fontSize={'1.125rem'}
          fontWeight={400}
          lineHeight={'1.575rem'}
          align={'center'}
        >
          {t('NOTFOUND.HELPFUL_LINKS')}
        </Typography>
      </Box>
      <Grid
        container
        direction={'row'}
        justifyContent={'center'}
        columnSpacing={1.5}
        marginTop={'3rem'}
        marginBottom={'4rem'}
      >
        <Grid width="13.5rem">
          <DarkButton onClick={redirectToStartPage}>{t('NOTFOUND.START_PAGE')}</DarkButton>
        </Grid>
        <Grid width="13.5rem">
          <SecondaryButton
            onClick={() => {
              history.push(`/contact`)
            }}
          >
            {t('NOTFOUND.CONTACT')}
          </SecondaryButton>
        </Grid>
        <Grid width="13.5rem">
          <SecondaryButton
            onClick={() => {
              history.push(`/stores`)
            }}
          >
            {t('NOTFOUND.STORES')}
          </SecondaryButton>
        </Grid>
      </Grid>
    </>
  )
}
export default Custom404

export async function getStaticProps() {
  return {
    props: {},
  }
}
